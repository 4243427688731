<template>
  <div class="score-query" v-if="status.queryStatus">
    <el-row class="score-query-title">
      <el-col>
        <div style="position: relative">
          <el-icon class="btn" v-show="status.queryStatus" @click="back"
            ><ArrowLeftBold
          /></el-icon>
        </div>
        {{ state.testInfo.name }}</el-col
      >
    </el-row>
    <div class="score-query-subtitle">考试成绩</div>
    <el-row class="score-table">
      <el-col>
        <el-table
          :data="state.queryData"
          :border="true"
          style="width: 100%"
          :header-cell-style="{
            color: '#333333',
            fontWeight: 'bold',
            textAlign: 'center',
          }"
          :cell-style="{
            color: '#333333',
            fontWeight: 'bold',
            textAlign: 'center',
          }"
        >
          <el-table-column
            v-for="(item, index) in state.queryMain"
            :key="index"
            :prop="item"
            :label="item"
          />
        </el-table>
      </el-col>
    </el-row>
  </div>
  <div class="score-query" v-else>
    <el-row class="score-query-title">
      <el-col>
        <div style="position: relative">
          <el-icon class="btn" @click="$router.push('/protal')"
            ><ArrowLeftBold
          /></el-icon>
        </div>
        成绩查询</el-col
      >
    </el-row>
    <!-- <el-row>
      <el-col class="message-content"> <el-button type="primary" style="float:right;margin-bottom: 0.0521rem;" @click="status.dialogVisible=true">准考证号成绩查询</el-button> </el-col>
    </el-row> -->
    <el-row v-if="state.errorMessage">
      <el-col
        ><div class="errorMessage">{{ state.errorMessage }}</div></el-col
      >
    </el-row>
    <el-row v-else class="message-content">
      <el-col>
        <div style="height: 73vh; overflow: hidden">
          <div
            class="message-item"
            v-for="(item, index) in state.examList"
            :key="index"
          >
            <div class="message-item-left">
              <div>考试名称：{{ item.name }}</div>
              <div>
                考试时间：<span
                  >{{
                    moment(item.stime).format("YYYY 年 MM 月 DD 日 HH:mm")
                  }}~{{
                    moment(item.etime).format("YYYY 年 MM 月 DD 日 HH:mm")
                  }}</span
                >
              </div>
            </div>

            <div class="message-item-right">
              <el-button type="primary" @click="getQueryByTicketId(item)"
                >选择</el-button
              >
            </div>
          </div>
        </div>
        <el-pagination
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total"
          style="float: right"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <el-dialog
    v-model="status.dialogVisible"
    :close-on-click-modal="false"
    title="准考证号查询成绩"
    width="30%"
  >
  <el-form :model="ticketForm">
      <el-form-item label="考试：" label-width="140px">
        <el-select v-model="ticketForm.examId" filterable placeholder="请选择考试" style="width:300px" >
          <el-option v-for="(item,index) in ticketForm.examList" :key="index" :label="item.examName" :value="item.examId" />
        </el-select>
      </el-form-item>
      <el-form-item label="准考证号：" label-width="140px">
        <el-input v-model="ticketForm.ticket" autocomplete="off" style="width:300px" />
      </el-form-item>
      <el-form-item label="身份证号：" label-width="140px">
        <el-input v-model="ticketForm.idCard" autocomplete="off" style="width:300px" />
      </el-form-item>
      
    </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="ticketGet">
          查询
        </el-button>
      </span>
    </template>
  </el-dialog>

  </div>
</template>
<script setup>
import { post, get } from "@/api/api";
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import moment from "moment";

onMounted(() => {
  getExamListBySwitch();
  getExamListByTimeAndState()
});
//data
let state = reactive({
  testInfo: {}, // 考试信息
  examList: [], // 考试列表
  queryMain: [], // 成绩信息
  queryData: [{}],
  errorMessage: ''
})
// 页面状态
let status = reactive({
  queryStatus: false,
  dialogVisible: false
})
// 分页数据
let pagination = reactive({
  pageSize: 6,
  pageNo: 1,
  total: 0,
});
// 准考证号查询成绩
let ticketForm = reactive({
  ticket: '',
  examId: '',
  idCard:'',
  examList:[]
})
// methods
// 获取可查询成绩考试
const getExamListBySwitch = async () => {
  let res = await get("/examApply/getExamListBySwitch", pagination);
  if (res.code === 200) {
    state.examList = res.result.list
    pagination.total = res.result.total
    if (res.result.list.length == 0) {
      state.errorMessage = res.message
    } else {
      state.errorMessage = ''
    }
  } else {
    ElMessage.error(res.message)
  }
};
// 通过考试id查询成绩
const getQueryByTicketId = async (row) => {
  state.testInfo = row
  let res = await get("/examApply/queryByTicketId", { examId: row.id });
  if (res.code === 200) {
    state.queryInfo = { ...res.result }
    delete state.queryInfo['准考证号']
    delete state.queryInfo['姓名']
    delete state.queryInfo['总成绩']
    for (const key in state.queryInfo) {
      const element = state.queryInfo[key];
      state.queryMain.push(key)
      state.queryData[0][key] = element
    }
    state.queryMain.unshift('准考证号')
    state.queryMain.unshift('姓名')
    // state.queryMain.push('总成绩')
    state.queryData[0]['准考证号'] = res.result['准考证号']
    state.queryData[0]['姓名'] = res.result['姓名']
    // state.queryData[0]['总成绩'] = res.result['总成绩']
    console.log(state);
    status.queryStatus = true
  } else {
    ElMessage.error(res.message)
  }
};

// 通过准考证号查询成绩
const getQueryByTicketNum = async () => {
  let res = await get("/examApply/notSignGetScore", { examId: ticketForm.examId, ticket: ticketForm.ticket,idCard:ticketForm.idCard});
  if (res.code === 200) {
    state.queryInfo = { ...res.result }
    delete state.queryInfo['准考证号']
    delete state.queryInfo['姓名']
    delete state.queryInfo['总成绩']
    // 

    let keys=Object.keys(state.queryInfo)
    if (keys.includes('笔试成绩') && keys.includes('原始面试成绩') && keys.includes('加权系数') && keys.includes('面试成绩')) {
      console.log(true);
      state.queryMain.push('笔试成绩')
      state.queryData[0]['笔试成绩'] = state.queryInfo['笔试成绩'];
      state.queryMain.push('原始面试成绩')
      state.queryData[0]['原始面试成绩'] = state.queryInfo['原始面试成绩'];
      state.queryMain.push('加权系数')
      state.queryData[0]['加权系数'] = state.queryInfo['加权系数'];
      state.queryMain.push('面试成绩')
      state.queryData[0]['面试成绩'] = state.queryInfo['面试成绩'];
    } else {
      for (const key in state.queryInfo) {
      const element = state.queryInfo[key];
      state.queryMain.push(key)
      state.queryData[0][key] = element
    }
    }

    state.queryMain.unshift('准考证号')
    state.queryMain.unshift('姓名')
    state.queryMain.push('总成绩')
    state.queryData[0]['准考证号'] = res.result['准考证号']
    state.queryData[0]['姓名'] = res.result['姓名']
    state.queryData[0]['总成绩'] = res.result['总成绩']
    console.log(state);
    status.dialogVisible=false
    status.queryStatus = true
  } else {
    ElMessage.error(res.message)
  }
};
// 获取考试列表
const getExamListByTimeAndState = async () => {
  let res = await get("/examApply/getExamListByTimeAndState", {
  pageSize: 9999,
  pageNo: 1,
});
  if (res.code === 200) {
    ticketForm.examList = res.result.list;
  } else {
    ElMessage.error(res.message);
  }
};
// 返回
const back = () => {
  state.queryMain = []
  state.queryData = [{}]
  status.queryStatus = false
};
// 分页切换
const handleCurrentChange = (num) => {
  pagination.pageNo = num;
  getExamListBySwitch()
};
const ticketGet=()=>{
  if(!ticketForm.examId){
    ElMessage.error('请选择考试')
    return
  }
  getQueryByTicketNum()
}
watch(
  () => status.dialogVisible,
  (newVal, oldVal) => {
    if(newVal===false){
      ticketForm.ticket=''
      ticketForm.examId=''
      ticketForm.idCard=''
    }
  }
);
</script>
  <style lang="scss" scoped>
.score-query {
  background-color: #ffffff;
}
.message-content {
  padding: 0 0.3125rem;
}
.score-query-title {
  text-align: center;
  height: 0.3125rem;
  line-height: 0.3125rem;
  font-size: 0.1563rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  border-bottom: 0.0052rem solid #e5e5e5;
  margin-bottom: 0.0521rem;
  .btn {
    position: absolute;
    left: 0;
    width: 0.3125rem;
    height: 0.3125rem;
    font-size: 0.0833rem;
    cursor: pointer;
  }
}
.message-item {
  display: flex;
  justify-content: space-between;
  height: 10vh;
  background: #ffffff;
  border: 0.0052rem solid #d2d2d2;
  border-radius: 0.0208rem;
  margin-bottom: 0.1042rem;
  // cursor: pointer;
  padding-left: 0.1042rem;
  border-left: 0.0208rem solid #097afa;
  .message-item-left {
    width: 90%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    div {
      font-size: 0.0938rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      padding: 0.0313rem 0;
    }

    div:nth-child(2) span {
      font-size: 0.0729rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  .message-item-right {
    width: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 0.1042rem;
  }
}
.message-item-disabled {
  border-left: 0.0208rem solid #d2d2d2;
}
// .message-item:hover {
//   background-color: #eee;
// }
.el-button {
  margin: 0;
  background-color: #097afa;
}
.is-disabled,
.is-disabled:hover {
  border: none;
  color: #999999;
  background-color: #eeeeee;
}
.score-query-subtitle {
  height: 0.4167rem;
  line-height: 0.4167rem;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.score-table {
  padding: 0 0.1146rem 0.3125rem;
}
.errorMessage {
  height: 3.6979rem;
  line-height: 3.6979rem;
  font-size: 24px;
  color: #ccc;
  text-align: center;
}
</style>